<template>
  <div v-if="show" class="page-container">
    <div v-if="device == 'ios' && show" v-loading="scanLoading" id="qr-reader" class="qr-reader"></div>
    <qr-stream v-if="device == 'android' && show" v-loading="scanLoading"  @decode="onDecode" class="qr-reader">
      <img class="scan-img" src="@/assets/img/scan2.png" alt="">
    </qr-stream>
    <video src=""></video>
    <el-button v-if="show" @click="close" class="exit" type="primary" text>退出</el-button>

  </div>

  <div style="width: 100%;padding-top: 100px">
    <div style="text-align: center;margin-bottom: 30px">
      <el-button :disabled="scanLoading" :loading="scanLoading" @click="open('out')" type="primary">
        <span>{{out ? '已扫 ': '码1'}}{{out}}</span>
      </el-button>
    </div>

    <div style="text-align: center;margin-bottom: 30px">
      <el-button :disabled="scanLoading" :loading="scanLoading" @click="open('inset')" type="primary">
        <span>{{inset ? '已扫 ': '码2'}}{{inset}}</span>
      </el-button>
    </div>

    <div style="text-align: center;margin-bottom: 30px">
      <el-button   :disabled="scanLoading || !out || !inset" :loading="scanLoading" @click="active()" type="primary">
        <span>激活</span>
      </el-button>
    </div>

  </div>



</template>

<script  setup>
import { QrStream} from 'vue3-qr-reader';
import {Html5Qrcode, Html5QrcodeScanner} from "html5-qrcode";
import { reactive, onMounted, onUnmounted } from "vue";

import { ref } from 'vue';
import { ElMessage  } from 'element-plus';
import * as api from '@/api/index'

var u = navigator.userAgent;
var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

const type = ref('')
const out = ref('')
const inset = ref('')
const info = ref()
const size = ref(150)
const formRef = ref()
const logged = ref(false)
const id = ref(null)
const scan = ref(false);
const scanLoading = ref(false)
const device = ref('');

const show = ref(false);

device.value = isiOS? 'ios': 'android';

const state = reactive({
  html5QrCode: null,
  fileList: [],
});

function isEvenString(str) {
  // 将字符串最后一个字符转换为数字
  var lastDigit = parseInt(str.charAt(str.length - 1));
  // 判断数字是否为偶数
  return lastDigit % 2 === 0;
}

const onCheck = async (pdf) => {
  if (type.value == 'out') {
    out.value = pdf
  }
  if (type.value == 'inset') {
    inset.value = pdf
  }
  scanLoading.value = false
  close();
}

const onDecode = (pdf) => {
  if (pdf && scanLoading.value == false) {
    onCheck(pdf);
  }
}

const getCamerasInfo = () => {
  Html5Qrcode.getCameras()
      .then((devices) => {
        if (devices && devices.length) {
          state.html5QrCode = new Html5Qrcode("qr-reader");
          startScan();
        }
      })
      .catch((err) => {
        ElMessage.error('摄像头无访问权限!')

      });
};

let pervDecodedText = ''

const startScan = () => {
  state.html5QrCode
      .start(
          { facingMode: "environment" },
          {
            fps: 5,
            qrbox: { width: 300, height: 300 },
          },
          (decodedText, decodedResult) => {
            if (pervDecodedText != decodedText) {
              onDecode(decodedText)
              pervDecodedText = decodedText;
            }

          }
      )
      .catch((err) => {
        console.log("扫码失败", err);
      });
};

const stopScan = () => {
  state.html5QrCode
      .stop()
      .then((a) => {
        console.log("已停止扫码", a);
      })
      .catch((err) => {
        console.log(err);
      });
};

const open = (t) => {
  show.value = true
  type.value = t;
  if (device.value == 'ios') {
    setTimeout(() => {
      getCamerasInfo();
    }, 500)
  }
}
const close = () => {
  show.value = false
  if (device.value == 'ios') {
    if (state.html5QrCode?.isScanning) {
      stopScan();
    }
  }
}

const active = async (v) => {
  // if(isEvenString(out.value) == isEvenString(inset.value)) {
  //   ElMessage.error('大小码匹对错误!')
  //   return;
  // }
  if (out.value == inset.value) {
    ElMessage.error('匹对错误!')
    return;
  }
  if (out.value && inset.value) {
    scanLoading.value = true
    try {
      const {data} = await api.record({out: out.value, inset: inset.value})
      if (data.code == 1) {
        ElMessage.success('绑定成功!')
      } else {
        ElMessage.error(data.msg)
      }
    } catch (e) {
      console.log(e)
    } finally {
      scanLoading.value = false
    }
  } else {
    ElMessage.error('匹对错误!')
    return;
  }



}
// onMounted(() => {
//   if (device.value == 'ios') {
//     setTimeout(() => {
//       getCamerasInfo();
//     }, 500)
//   }
//
// });

onUnmounted(() => {
  if (device.value == 'ios') {
    if (state.html5QrCode?.isScanning) {
      stopScan();
    }
  }
});


</script>
<style lang="scss">
#qr-reader, .qr-reader{
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  video{
    @include center();
    object-position: center;
    object-fit: contain;
  }
  .scan-img{
    width: 200px;
    height: 200px;
    opacity: 0.3;
    @include center();

  }
  .exit{
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 999;
  }
}
</style>
<style lang="scss" scoped>


.page-container{
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #333;
  transition: 0.5s;
  .exit{
    z-index: 999;
    position: absolute;
    top: 4px;
    right: 4px;
  }
  .bg{
    width: 250px;
    position: absolute;
    bottom: 0;
    left: 0;
    transition: 0.5s ease;

  }
}

.el-form-item:not(:last-child) {
  padding-bottom: 16px;
}

</style>
