import http from '@/axios/http';

export let debug = false;

let prefix = ''
let prefixTp = '';

if (debug) {
    prefix = '/api'
    prefixTp = '/tp';
} else {
    prefix = 'https://pjxphp.matesqs.com/'
    prefixTp = 'https://pjxphp.matesqs.com/';
}


export function record(params){
    return http.post(prefixTp + 'app/qr/update', params)
}
